import { template as template_62e22e635d9d4a8fadb03fe8040aa74a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_62e22e635d9d4a8fadb03fe8040aa74a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
