import { template as template_9116747ead39461990178c6b616acd5a } from "@ember/template-compiler";
const FKControlMenuContainer = template_9116747ead39461990178c6b616acd5a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
